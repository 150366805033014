<template>
  <div>
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'Products'">
    </breadcrumb-base>
    <transition name="fade">
      <add-denomination :product-image="productImageWebUrl" :product-id="productId"
                        v-if="showDenominationsScreen"
                        @denomination-cancel="cancelDenomination"
                        @denomination-added="denominationAdded"></add-denomination>
    </transition>
    <transition name="fade">
      <div class="content-center" v-if="!showDenominationsScreen">
        <div class="vx-col md:w-4/5 w-full mb-base c-center">
          <vx-card :title="$t('AddNewProduct')">
            <div class="vx-row">
              <div class="vx-col md:w-3/5 w-full">
                <div class="vx-row mb-2">
                  <div class="vx-col w-full">
                    <vs-input
                      :disabled="isProductAdded"
                      v-validate="'required'"
                      validate-on="change"
                      :name="$t('ProductName')"
                      class="w-full" :label-placeholder="$t('ProductName') + '*'"
                      v-model="productName"/>
                    <span class="text-danger text-sm">{{ errors.first($t('ProductName')) }}</span>
                  </div>
                </div>
                <div class="vx-row mb-2">
                  <div class="vx-col md:w-1/2 w-full">
                    <p class="p-type">{{ $t('ProductType')  + '*'}}</p>
                    <div class="vx-col w-full">
                      <v-select
                        :disabled="isProductAdded" label="name" :options="productTypes"
                        v-model="productType" :dir="$vs.rtl ? 'rtl' : 'ltr'"/>
                    </div>
                  </div>
                  <div class="vx-col md:w-1/2 w-full" v-if="productType != '' && productType.key !== 'gift card'">
                    <vs-input
                      :disabled="isProductAdded"
                      v-validate="'required'"
                      validate-on="change"
                      :name="$t('PinLength')"
                      class="w-full" type="number" :label-placeholder="$t('PinLength') + '*'"
                      v-model="pinLength"/>
                    <span class="text-danger text-sm">{{ errors.first($t('PinLength')) }}</span>
                  </div>
                </div>
                <div class="vx-row mb-2">
                  <div class="vx-col w-full">
                    <vs-input
                      :disabled="isProductAdded"
                      v-validate="'required'"
                      validate-on="change"
                      :name="$t('CustomerCareNumber')"
                      class="w-full" :label-placeholder="$t('CustomerCareNumber') + '*'"
                      v-model="customerCareNumber"/>
                    <span
                      class="text-danger text-sm">{{ errors.first($t('CustomerCareNumber')) }}</span>
                  </div>
                </div>

                


                <div class="vx-row mb-6 mt-6">
                  <div class="vx-col w-full">
                    <vs-textarea
                      :disabled="isProductAdded" :label="$t('Instructions') + '*'"
                      v-model="instructions"/>
                  </div>
                </div>
                <!-- QR Code Instructions -->
                <!-- <div class="vx-row mb-2">
                  <div class="vx-col w-full">
                    <vs-input
                      :disabled="isProductAdded" :label="$t('QRCodeInstructions') + '*'"
                      v-model="qrcodeinstructions"/>
                  </div>
                </div> -->

                <div class="vx-row mb-2">
                  <div class="vx-col w-full">
                    <vs-input
                      :disabled="isProductAdded"
                      v-validate="'required'"
                      validate-on="change"
                      :name="$t('CustomerCareNumber')"
                      class="w-full" :label-placeholder="$t('QR Code Instructions')"
                      v-model="qrcodeinstructions"/>
                  </div>
                </div>


              </div>
              <!-- Product Side -->
              <div class="vx-col md:w-2/5 w-full">
                <!-- Product Image -->
                <div class="p-image-holder c-center">
                  <vs-button v-if="productImageSrc !== '' && !isProductAdded" class="p-delete-button" radius color="danger" size="large" icon-pack="feather" icon="icon-trash" @click="deleteImage"></vs-button>
                  <img class="p-image" :src="productImageSrc" alt="">
                </div>
                <div class="c-center" v-if="!isProductAdded && productImageSrc === ''">
                  <div class="upload-btn-wrapper">
                    <div class="vx-row upload-contents">
                      <feather-icon icon="PlusIcon" svgClasses=""/>
                      <p class="pl-4">{{$t('AddProductImage') + '*'}}</p>
                    </div>
                    <input type="file" name="myImage" accept="image/*" ref="fileUpload"
                           @change="selectedNewFile"/>
                  </div>
                </div>
                <div class="m-2"></div>
                <div v-if="isProductAdded">
                  <h2 class="px-3 pt-3">Denominations</h2>
                  <div class="c-center pt-3"  v-if="productType.key === 'calling card' && denominations.length === 0">
                    <div class="upload-btn-wrapper">
                      <a @click="openDenominations"
                         class="upload-contents flex items-center justify-center cursor-pointer">
                        <div class="vx-row flex">
                          <feather-icon icon="PlusIcon" svgClasses=""/>
                          <p class="pl-4">{{$t('AddDenomination')}}</p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="c-center pt-3" v-if="productType.key !== 'calling card'">
                    <div class="upload-btn-wrapper">
                      <a @click="openDenominations"
                         class="upload-contents  flex items-center justify-center">
                        <div class="vx-row flex">
                          <feather-icon icon="PlusIcon" svgClasses=""/>
                          <p class="pl-4">{{$t('AddDenomination')}}</p>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="c-center vx-col m-3 pt-1" v-if="denominations.length > 0">
                    <div class="mt-3" v-for="d in denominations">
                      <div
                        class="vx-row upload-contents upload-contents-denomination flex items-center justify-around py-3">
                        <img class="p-image-denomination flex" :src="productImageWebUrl" alt="">
                        <h1 class="flex">{{d | germanNumberFormat}}</h1>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="vx-row mt-6">
              <vs-button v-if="!isProductAdded" class="ml-4" :disabled="!validateForm"
                         @click="addProductAndForward">{{ $t('SaveAddDenominations') }}
              </vs-button>
              <vs-button v-if="isProductAdded" class="ml-4" @click="doneGoList">{{ $t('Done') }}
              </vs-button>
            </div>
            <div class="m-4"></div>

          </vx-card>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import AddDenomination from './AddDenomination'

export default {
  name: 'AddProducts',
  components: {
    'v-select': vSelect,
    AddDenomination,
  },
  computed: {
    validateForm() {
      if (this.productType.key === 'gift card') {
        return !this.errors.any() && this.productName !== '' && this.customerCareNumber !== '' && this.instructions !== '' && this.productType !== '' && this.productImage !== null
      }
      return !this.errors.any() && this.productName !== '' && this.pinLength !== '' && this.customerCareNumber !== '' && this.instructions !== '' && this.productType !== '' && this.productImage !== null
    },
  },
  data() {
    return {
      productName: '',
      pinLength: '',
      customerCareNumber: '',
      instructions: '',
      qrcodeinstructions: '',
      productType: '',
      productImage: null,
      productId: '',
      productImageWebUrl: '',
      productTypes: [
        {
          'name': 'Cash Cards',
          'key': 'cash card',
        },
        {
          'name': 'Gift Cards',
          'key': 'gift card',
        },
        {
          'name': 'Calling Cards',
          'key': 'calling card',
        },
      ],
      productImageSrc: '',
      isProductAdded: false,
      denominations: [],
      showDenominationsScreen: false,

      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Products', i18n: 'Products', url: '/products/view' },
        { title: 'Add Product', i18n: 'AddProduct', active: true },
      ],
    }
  },
  methods: {
    deleteImage() {
      this.productImage = null
      this.productImageSrc = ''
    },
    async selectedNewFile(e) {
      this.productImage = this.$refs.fileUpload.files[0]
      const readURL = (file) => new Promise((res, rej) => {
        const reader = new FileReader()
        reader.onload = (e) => res(e.target.result)
        reader.onerror = (e) => rej(e)
        reader.readAsDataURL(file)
      })

      this.productImageSrc = await readURL(this.productImage)
    },
    addProductAndForward() {
      if (this.productType.key == 'gift card') {
        this.pinLength = 0
      }
      const payload = {
        productName: this.productName,
        productType: this.productType.key,
        pinLength: this.pinLength,
        customerCareNumber: this.customerCareNumber,
        instructions: this.instructions,
        productImage: this.productImage,
        qrcodeinstructions: this.qrcodeinstructions
      }
      this.$vs.loading()

      this.$store.dispatch('products/addNewProduct', payload).then((data) => {
        this.$vs.loading.close()
        this.isProductAdded = true
        this.$vs.notify({
          position: 'top-center',
          title: 'Success',
          text: data.message,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'success',
        })
        this.productImageWebUrl = data.data.product_image
        this.productId = data.data.product_id

        this.isProductAdded = true
      }).catch((error) => {
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
    },
    doneGoList() {
      if (this.denominations.length === 0) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Please add at least one denomination',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      this.$router.push({ name: 'products-view' })
    },
    openDenominations() {
      this.showDenominationsScreen = true
    },
    denominationAdded(value) {
      this.denominations.push(value)
      this.showDenominationsScreen = false
    },
    cancelDenomination() {
      this.showDenominationsScreen = false
    },
  },
}
</script>

<style>
  .c-center {
    margin: auto;
  }

  .p-type {
    padding: 0 1rem;
    font-size: 10px;
  }

  .p-type-select {
    padding: 0 0 0 1rem;
  }

  .vx-card__title > h4 {
    font-weight: bold !important;
  }

  .upload-btn-wrapper {
    margin: 1rem;
    position: relative;
    display: flex;
  }

  .upload-contents {
    border: 1px dashed  #00000040;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
    color: #2C2C2C;
    background-color: white;
    padding: 30px 30px;
    font-size: 20px;
    font-weight: bold;
    width: 100%;
    margin: auto;
  }

  .upload-btn-wrapper-denomination {
    position: absolute;
  }

  .upload-contents-denomination {
    padding: 5px;
  }

  .upload-btn-wrapper input[type=file] {
    position: absolute;
    margin: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
  }

  .p-image-holder {
    position: relative;
    display: flex;
  }

  .p-image {
    width: 90%;
    object-fit: cover;
    max-height: 200px;
    margin: 1rem;
  }

  .p-delete-button {
    position: absolute;
    float: right;
    right: 0;
    margin: 0.5rem;
  }

  .p-image-denomination {
    width: 50%;
  }
</style>
